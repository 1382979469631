<template>
  <v-container class="backup-container" fluid>
    <v-row class="system-inner-row">
      <v-col cols="12" class="pa-5 synapsa-tabs">
        <v-card class="theme-provider">
          <div class="backup-tab backup-configuration-tab d-flex align-start">
            <div class="backup-tab-item configuration-column-left">
            <h4 class="mb-n2 system-h4">Export</h4>
            <div class="toggle">
              <v-switch
                v-model="checkbox"
                :input-value="true"
                true-value
                disabled
                label="Full configuration"
                hide-details
                inset
                :class="[checkbox ? 'v-switch-on' : 'v-switch-off']"
                class="target-background v-switch-off"
              />
            </div>
              <v-btn
                color="primary"
                :loading="configLoading"
                @click="exportConfig()"
                class="backup-btn mb-5"
                rounded
                small
              >Create</v-btn
              >
            </div>
            <div class="backup-tab-item configuration-column-right">
              <h4 class="mb-2 system-h4">Restore</h4>
              <v-file-input
                v-model="configFile"
                class="file-input mb-5"
                accept=".xml"
                label="Choose file..."
                append-icon="mdi-folder"
                prepend-icon=""
                rounded
                filled
                dense
                single-line
                hide-details="auto"
                :disabled="configImportLoading"
              ></v-file-input>
              <v-btn
                color="primary"
                :disabled="!configFile > 0"
                :loading="configImportLoading"
                @click="saveFileConfiguration"
                class="backup-btn"
                rounded
                small
              >Restore</v-btn
              >
            </div>
          </div>
        </v-card>
        <backup-table
          :headers="headers"
          :data="data"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  exportConfiguration, getBackupConfiguration,
  importConfiguration,
} from "@/api/system/backup";
import { Errors } from "@/utils/error";
import permission from "@/directive/permission/permission.js";
import backupTable from "@/views/system/backup/configuration/backupTable.vue";
export default {
  components: {
    backupTable,
  },
  directives: { permission },
  data() {
    return {
      configLoading: false,
      configImportLoading: false,
      configFile: null,
      backupFile: null,
      permissions: localStorage.getItem("permissions").split(","),
      checkbox: true,

      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Size", value: "size", sortable: false },
        { text: "Download", value: "execute", width: 100, sortable: false },
      ],
      data: [],
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getBackupConfiguration()
        .then((response) => {
          this.loading = false;
          this.data = response;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
    saveFileConfiguration() {
      let formData = new FormData();
      if (this.configFile) {
        formData.append("file", this.configFile);
        this.configImportLoading = true;
        importConfiguration(formData)
          .then(() => {
            this.configImportLoading = false;
            this.$toast.open({
              message: "Import has been successful! Please check your firewalls and apis credentials.",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
          })
          .catch((error) => {
            this.configImportLoading = false;
            console.log({ error });
          });
      }
    },
    exportConfig() {
      const data = {
        export: "all",
      };
      this.configLoading = true;
      exportConfiguration(data)
        .then((response) => {
          this.configLoading = false;
          this.$toast.open({
            message: response.message,
            type: 'success',
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.configLoading = false;
          Errors.show(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.system-h4 {
  font-size: 14px !important;
}
.theme-provider {
  padding: 0;
  box-shadow: none !important;
  margin: 0;
  background: none !important;
  border-radius: 15px;
}
.btn-group {
  width: 100%;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
}

.theme-provider.theme--dark ::v-deep {
  .btn-group {
    background: hsla(0, 0%, 100%, 0.08);
  }
}
.backup-checkbox {
  width: 100%;
}
.row + .row {
  margin-top: 0px;
}
.system-inner-row {
  margin-top: 0px !important;
}
.synapsa-tabs {
  margin: 0;
  border-radius: 15px !important;
}
.backup-container {
  font-family: RobotoCondensedRegular, sans-serif !important;
  padding-top: 0;
  margin-top: -4px;
}
.backup-tab-item {
  /* padding: 20px; */
  padding-top: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.file-input {
  width: 100%;
  border-radius: 15px;
}
.backup-btn {
  border-radius: 15px;
  transition: none;
}

.backup-btn:hover {
  color: white !important;
}

h4 {
  font-family: UbuntuBold, sans-serif;
  font-size: 18px;
}
.v-input ::v-deep {
  .v-input__slot {
    width: calc(100% - 10px) !important;
    border-radius: 12px !important;
  }
}

.v-input__slot {
  width: calc(100% - 10px) !important;
  border-radius: 12px !important;
}

.toggle ::v-deep {
  margin: 35px 0 55px 0;
  min-width: 100%;
  .v-label {
    min-width: 110% !important;
  }
}
</style>
