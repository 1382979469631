<template>
  <v-row>
    <v-col cols="4" class="pt-8 px-0">
      <v-data-table
        class="remove-table-style"
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :loading="loading"
        loading-text="Loading data from API...please wait"
      >
        <template v-slot:item.name="{ item }">
          <span class="form-link">
            {{ item.name }}
          </span>
        </template>

        <template v-slot:item.execute="{ item }">
          <template v-if="item.execute">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="download(item)"
                >
                  mdi-download
                </v-icon>
              </template>
              <span>Download</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { Errors } from "@/utils/error";
import { downloadBackupConfiguration } from "@/api/system/backup";

export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    download(file) {
      file.execute = true;
      downloadBackupConfiguration(file.name)
        .then((response) => {
          file.execute = false;
          this.configLoading = false;
          var fileURL = window.URL.createObjectURL(
            new Blob([response], { type: "application/xml" })
          );
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "configuration_" + file.name);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((e) => {
          Errors.show(e);
          file.execute = false;
        });
    },
  },
};
</script>

<style>
.remove-table-style tbody tr td {
  /* Odstrániť štýl pre tabuľku */
  padding: 0;
  border-top: none;
}
</style>
